<app-header></app-header>
<div>
  <div class="portal__app portal__recipe-header" *ngIf="recipeEnabled">
    <a href="/recipe">
      <span>Recipe</span>
      <img src="assets/icons/recipe.png">
    </a>
  </div>
  <div class="portal__app portal__nope-header" *ngIf="nopeEnabled">
    <span>no dice</span>
    <img src="assets/icons/nope.webp">
  </div>
  <div class="portal__app portal__meme-header" *ngIf="memesEnabled">
    <a href="/memes">
      <span>Memes</span>
      <img src="assets/icons/3-2-troll-face-meme-png_64x64.ico">
    </a>
  </div>
  <div class="portal__app portal__wine-header" *ngIf="wineEnabled">
    <a href="/wineTasting">
      <span>Wine Tasting</span>
      <img src="assets/icons/wine.jpeg">
    </a>
  </div>
  <div class="portal__app portal__funko-header" *ngIf="funkoEnabled">
    <a href="/funko">
      <span>Funko</span>
      <img src="assets/icons/funko.jpeg">
    </a>
  </div>
  <div class="portal__app portal__gas-header" *ngIf="gasEnabled">
    <a href="/gas">
      <span>Gas</span>
      <img src="assets/icons/gas-can.png">
    </a>
  </div>
</div>
