<app-feeding-history-type
   [source]="source">
</app-feeding-history-type>
<app-feeding-history-quantity
   [source]="source">
</app-feeding-history-quantity>
<app-feeding-history-bottles
   [source]="source">
</app-feeding-history-bottles>
<app-feeding-history-feeds
   [source]="source">
</app-feeding-history-feeds>

<!-- scatter cart of time of day -->