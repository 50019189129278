<h2>Add Wine</h2>
<div>
  <label>Wine Name</label>
  <input type="text" name="name" id="name" #name>
  <br/>
  <label>Wine Style</label>
  <input (keyup.enter)="submit()" type="text" name="style" id="style" #style>
</div>
<div>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="submit()">Submit</button>
</div>
