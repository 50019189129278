<h2>Add Gas</h2>
<div class="container">
  <div class="row">
    <label>Date</label>
    <input type="text" name="date" id="date" #date>
  </div>
  <div class="row">
    <label>Vehicle</label>
    <input type="text" name="vehicle" id="vehicle" #vehicle>
  </div>
  <div class="row">
    <label>Odometer</label>
    <input type="number" name="odd" id="odd" #odd>
  </div>
  <div class="row">
    <label>Cost</label>
    <input (keyup.enter)="submit()" type="number" name="cost" id="cost" #cost>
  </div>
  <div class="row">
    <label>Gas</label>
    <input type="number" name="gas" id="gas" #gas>
  </div>
</div>
<div class="btn-container">
  <button mat-raised-button (click)="submit()">Submit</button>
</div>
