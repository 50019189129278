<div *ngIf="vehicle">
  <ngx-charts-line-chart
    [view]="[1000,400]"
    [results]="mpgData"
    [xAxisLabel]="'Products'"
    [legendTitle]="'Dummy Data'"
    [yAxisLabel]="'Sales'"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [xAxis]="true"
    [yAxis]="true"
    [gradient]="true">
  </ngx-charts-line-chart>
</div>

<div *ngIf="!vehicle">
  <!--  <div *ngFor="derp">-->
  <!--    <a href="gas?vehicle${derp}">{{derp}}</a>-->
  <!--  </div>-->
  <span>Vehicle not set</span>
</div>
