<svg viewBox="0 0 649.67 123.574" [attr.height]="size" [attr.width]="size * 5" xmlns="http://www.w3.org/2000/svg">
  <clipPath id="starFill">
    <path d="M 64.967 0 L 85.043 40.678 L 129.934 47.201 L 97.45 78.864 L 105.119 123.574 L 64.967 102.465 L 24.815 123.574 L 32.484 78.864 L 0 47.201 L 44.891 40.678 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
    <path d="M 194.901 0 L 214.977 40.678 L 259.868 47.201 L 227.384 78.864 L 235.053 123.574 L 194.901 102.465 L 154.749 123.574 L 162.418 78.864 L 129.934 47.201 L 174.825 40.678 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
    <path d="M 324.835 0 L 344.911 40.678 L 389.802 47.201 L 357.318 78.864 L 364.987 123.574 L 324.835 102.465 L 284.683 123.574 L 292.352 78.864 L 259.868 47.201 L 304.759 40.678 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
    <path d="M 454.769 0 L 474.845 40.678 L 519.736 47.201 L 487.252 78.864 L 494.921 123.574 L 454.769 102.465 L 414.617 123.574 L 422.286 78.864 L 389.802 47.201 L 434.693 40.678 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
    <path d="M 584.703 0 L 604.779 40.678 L 649.67 47.201 L 617.186 78.864 L 624.855 123.574 L 584.703 102.465 L 544.551 123.574 L 552.22 78.864 L 519.736 47.201 L 564.627 40.678 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
  </clipPath>

  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
    <stop offset="0%" style="stop-color:#26C6DA;stop-opacity:1" />
    <stop offset="100%" style="stop-color:#512DA8;stop-opacity:1" />
  </linearGradient>

  <rect x="0" y="0" [attr.width]="649.67 * percent" height="123.574" fill="url(#gradient)" clip-path="url(#starFill)"></rect>

  <g fill="transparent" stroke-width="10" stroke-linecap="round">
	  <path d="M 64.967 0 L 85.043 40.678 L 129.934 47.201 L 97.45 78.864 L 105.119 123.574 L 64.967 102.465 L 24.815 123.574 L 32.484 78.864 L 0 47.201 L 44.891 40.678 Z" style="stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
	  <path d="M 194.901 0 L 214.977 40.678 L 259.868 47.201 L 227.384 78.864 L 235.053 123.574 L 194.901 102.465 L 154.749 123.574 L 162.418 78.864 L 129.934 47.201 L 174.825 40.678 Z" style="stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
	  <path d="M 324.835 0 L 344.911 40.678 L 389.802 47.201 L 357.318 78.864 L 364.987 123.574 L 324.835 102.465 L 284.683 123.574 L 292.352 78.864 L 259.868 47.201 L 304.759 40.678 Z" style="stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
	  <path d="M 454.769 0 L 474.845 40.678 L 519.736 47.201 L 487.252 78.864 L 494.921 123.574 L 454.769 102.465 L 414.617 123.574 L 422.286 78.864 L 389.802 47.201 L 434.693 40.678 Z" style="stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
	  <path d="M 584.703 0 L 604.779 40.678 L 649.67 47.201 L 617.186 78.864 L 624.855 123.574 L 584.703 102.465 L 544.551 123.574 L 552.22 78.864 L 519.736 47.201 L 564.627 40.678 Z" style="stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, 1, 7.105427357601002e-15, 0)"/>
	</g>
</svg>


<!-- background-image: linear-gradient(.4turn,#512DA8,#26C6DA,#673AB7); -->